@import "../shared/styles/colors";

// Sections

.hero-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $background-tertiary-lp;
  border: 5px solid $background-base-lp;
  border-top: 0;
  border-radius: 12px;
  padding: 0 calc(1.5rem - 5px);

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    width: 100%;
    padding: 7.5rem 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    max-width: 500px;

    p {
      line-height: 160%;
      letter-spacing: -0.02em;
      color: $font-base-lp;
      text-align: center;
    }

    a {
      margin-top: 2rem;
    }
  }

  .image {
    display: none;
  }

  .trusted-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    width: 100%;
    max-width: 500px;
    margin-bottom: 3.5rem;

    .title {
      line-height: 160%;
      text-align: center;
      letter-spacing: -0.02em;
      color: $font-base-lp;
      margin: 0;
    }
  }
}

.quote-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 1.5rem;
  border: 5px solid $background-base-lp;
  border-bottom: 0;
  border-radius: 12px;
  width: 100%;
  min-height: 392px;

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    max-width: 500px;

    .subtitle {
      color: $background-tertiary-lp;
      font-family: "Playfair Display";
      font-style: italic;
      font-size: 1.25rem;
      line-height: 100%;
      text-align: center;
      letter-spacing: -0.055em;
    }

    .desc {
      line-height: 160%;
      font-size: 1rem;
      text-align: center;
      letter-spacing: -0.02em;
    }

    .button-box {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 1.5rem;
      min-width: max-content;

      a,
      button {
        width: 100%;
      }
    }
  }

  &.type-white {
    background-color: $background-base-lp;

    .wrapper {
      h1,
      h2,
      h3,
      p {
        color: $font-base-lp;
      }
    }
  }
  &.type-black {
    background-color: $background-secondary-lp;

    .wrapper {
      h1,
      h2,
      h3,
      p {
        color: $font-secondary-lp;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .hero-section {
    padding: 0 calc(3rem - 5px);
    gap: 0;

    .wrapper {
      justify-content: space-between;
      gap: 1.5rem;
      max-width: 100%;
      padding: 0;
      padding-bottom: 5rem;
    }

    .content {
      align-items: flex-start;
      width: 50%;
      h1,
      p {
        text-align: left;
      }
    }

    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;

      img {
        width: max-content;
      }
    }

    .trusted-wrapper {
      max-width: 100%;
    }
  }

  .quote-section {
    padding: 0;

    .wrapper {
      max-width: 45%;

      .button-box {
        flex-direction: row;

        a,
        button {
          width: auto;
        }
      }
    }
  }
}

@media only screen and (min-width: 1440px) {
  .hero-section {
    padding: 0;

    .wrapper {
      max-width: 65%;
    }
  }
}
