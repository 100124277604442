// NOTE: This is deprecated, use the tailwind theme instead
$background-base: #f4f5f7;
$background-component: #ffffff;
$font-base: #242325;
$font-secondary: #6b778c;
$font-tertiary: #363c46;
$font-active: #0165ff;
$font-active-darker: #0052cc;
$active-hover: rgba(1, 103, 255, 0.2);
$font-light: #ffffff;
$font-light-darker: #eaf2fe;
$green: #47bf7c;
$green-light: #edf9f2;
$disabled: #c3c4c6;
$disabled-button: #929394;
$disabled-text: #aeb5c1;
$border: #c3c4c6;
$border-light: #f4f5f7;
$shadow-color: #dfe1e6;
$error: #ef6277;
$error-background: #fdeff1;
$modal-background: "rgba(1, 55, 146, 0.9)";

$white: #ffffff;

// Landing page
$background-base-lp: #ffffff;
$background-secondary-lp: #111317;
$background-tertiary-lp: #f4f5f7;
$font-base-lp: #2b3038;
$font-secondary-lp: #f4f5f7;
$font-tertiary-lp: #6f7c91;
$font-quaternary-lp: #ffffff;
$border-focus-lp: #d3d7dd;
$border-primary-lp: rgba(1, 101, 255, 0.2);
$border-secondary-lp: #6f7c91;
$border-tertiary-lp: #a7afbb;
$border-quaternary-lp: #d4d6da;
$blue: #0165ff;
$blue-light: #f3f8ff;
$blue-light-darker: #e6f0ff;
$orange: #f7bf53;
$orange-light: #fef9ee;
$red: #c93f59;
$red-light: #fff0f2;
$green-lp: #119a5a;
