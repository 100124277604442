@import "../../shared/styles/colors";

@mixin card-component {
  background-color: "#FFFFFF";
  border-radius: 12px;
  box-shadow: 0px 1px 0 0 #dfe1e6;
}

.quiz-wrapper {
  @include card-component;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  padding: 4.5rem 1.6875rem 1.5rem 4.5rem;

  h2 {
    font-size: 1.3125rem;
    color: $font-base;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 0.75rem;
    line-height: 1.5rem;
    color: $font-secondary;
  }

  .actions {
    display: flex;
    justify-content: flex-end;

    button {
      display: flex;
      width: auto;
    }
  }
}

.quiz-question-wrapper {
  @include card-component;
  padding: 2rem;
  width: 80vw;

  ul {
    margin-top: 1.5rem;
    margin-bottom: 2rem;

    li {
      margin-bottom: 1rem;

      label {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
      }
      .checkbox {
        padding: 0;
        margin: 0 0.5rem 1rem 0.5rem;
      }
    }
  }

  button {
    width: auto;
  }
}
