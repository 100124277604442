.task-intro {
  .video-container {
    background: black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 1040px;
    width: 100%;
    border-radius: 0.75rem;
    height: 678px;
    z-index: 999;
  }

  .video-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(36, 35, 37, 0.5);
    z-index: 900;
    backdrop-filter: blur(2px);
  }
}
